.rhap_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 90px;
  width: 100%;
  min-width: 300px;
  padding: 10px 15px;
  background-color: #fff;
  position: relative;
  z-index: 100;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.rhap_progress-section {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}

.rhap_progress-container {
  display: flex;
  align-items: center;
  height: 18px;
  flex: 1 0 auto;
  align-self: center;
  margin: 0 3%;
  cursor: pointer;
  -webkit-user-select: none;
}

.rhap_time {
  color: #000;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
}

.rhap_progress-bar {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 5px;
  background: #e4e4e4;
  border-radius: 2px;
}

.rhap_progress-indicator {
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  top: -8px;
  background: #868686;
  opacity: 0.9;
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
}

.rhap_controls-section {
  display: flex;
  flex: 1 0;
  justify-content: space-between;
  margin-top: 8px;
}

.rhap_additional-controls {
  display: flex;
  flex: 1 0;
  align-items: center;
}

.rhap_repeat-button {
  font-size: 26px;
  width: 26px;
  height: 26px;
  color: #868686;
  margin-right: 6px;
}

.rhap_main-controls {
  flex: 0 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rhap_main-controls-button {
  margin: 0 3px;
  color: #868686;
  font-size: 35px;
  width: 35px;
  height: 35px;
}

.rhap_play-pause-button {
  font-size: 40px;
  width: 40px;
  height: 40px;
}

.rhap_volume-controls {
  display: flex;
  flex: 1 0;
  justify-content: flex-end;
}

.rhap_volume-button {
  font-size: 26px;
  width: 26px;
  height: 26px;
  color: #868686;
  margin-right: 6px;
}

.rhap_volume-container {
  display: flex;
  align-items: center;
  flex: 0 1 100px;
  -webkit-user-select: none;
}

.rhap_volume-bar-area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 14px;
  cursor: pointer;
}

.rhap_volume-bar {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 4px;
  background: #e4e4e4;
  border-radius: 2px;
}

.rhap_volume-indicator {
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  left: 0;
  top: -4px;
  background: #868686;
  opacity: 0.9;
  border-radius: 50px;
  box-shadow: rgba(134, 134, 134, 0.5) 0 0 3px;
  cursor: pointer;
}
.rhap_volume-indicator :hover {
  opacity: 0.9;
  transition-duration: 0.2s;
}

/* Utils */
.rhap_button-clear {
  background-color: transparent;
  border: none;
  outline: 0;
  padding: 0;
  cursor: pointer;
}
.rhap_button-clear :hover {
  opacity: 0.9;
  transition-duration: 0.2s;
}
.rhap_button-clear :active {
  opacity: 0.95;
}
